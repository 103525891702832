import React from "react";
import "./App.css";
import SPROCKET from './images/dog3.png';
import PLSX from './images/plsx.png';

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container">
      <header>
        <img src={SPROCKET} alt="Sprocket" />
        <h1>Sprocket Token</h1>
        <h3 className="subtitle">Join the Sprocket Community!</h3>
      </header>

      <section className="main">
        <h3 className="description subtitle">
          Sprocket Token <a href="https://scan.pulsechain.com/token/0x657EfB430a3D5b01bBD8F254AE3bd5b94caD590E" target="_blank" rel="noreferrer">(SPR)</a> is an ERC20 cryptocurrency on <a href="https://pulsechain.com/" target="_blank" rel="noreferrer">Pulsechain</a> inspired by the
          beloved 80s TV show "The Fraggles." Invest in Sprocket and be part of
          the Fraggle Economy, where fun and financial opportunities collide!
        </h3>

        <div className="cta-container">
          <a href="https://app.pulsex.com/swap?outputCurrency=0x657EfB430a3D5b01bBD8F254AE3bd5b94caD590E" target="_blank" rel="noreferrer"><button className="button">Buy Sprocket<img className="plsx" src={PLSX} alt="PLSX logo" /></button></a>
        </div>
      </section>

      <footer>
        <p className="year">&copy; {currentYear} Sprocket Token. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
